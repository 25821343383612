.k-btn{
  font-family: "Mark OT";
  font-weight: normal;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  text-decoration: none;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
}