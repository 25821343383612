

.content-tile{
  font-family: "Mark OT";
  width: 23.7%;
  height: 525px;
  border: 1px solid black;
  position: relative;
  overflow: hidden;
  min-width: 200px;
  max-width: 375px;
  .lower-image{
    position: absolute;
    left: 0;
    right: -10%;
    bottom: -10%;
    z-index: 1;
    width: 198%;
  }
  .upper-element-container{
    position: absolute;
    z-index: 3;
    width: 100%;
    right: -48%;
    top: -10%;
  }
  h3{
    font-family: MarkOT, serif;
    font-weight: normal;
    font-size: 3rem;
    line-height: 3rem;
    margin: 0 0 1rem 0;
  }
  a.promo-cta{
    :visited{
      color : #3B2B2F;
    }
    :active{

    }
    background-color: #ECE9E4;

  }
  .bg-image-container{
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .promo-message{
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 10%;
    bottom: 53%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding: 10%;
    box-sizing: border-box;
  }
  .upper-element{
    position: absolute;
    z-index: 3;
  }

  .bg-image{
    position: absolute;
    z-index: 0;
    top: 0;
    height: 100%;
  }
}

