@import "components/content-banner/content-tile.scss";

body{
  font-size: 16px;
}

@font-face {
  font-family: MarkOT;
  src: local('fonts/MarkOT.woff'), url('fonts/MarkOT.woff');
  font-weight : normal;
}

@font-face {
  font-family: MarkOT;
  src: local('fonts/MarkOT-Bold.woff'), url('fonts/MarkOT-Bold.woff');
  font-weight : bold;
}

.Apper{
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  .dev-tools{
    padding: 1rem;
  }
  .input-group{
    font-size: 0.75rem;
    display: flex;
    flex-flow: column;
  }
}